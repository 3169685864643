import { default as emailsaf2a6qOlI7Meta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/emails.vue?macro=true";
import { default as integrationsBsYf6Oefi3Meta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/integrations.vue?macro=true";
import { default as overvieweNerRGnAPBMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/overview.vue?macro=true";
import { default as index8uZTuhrbKuMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectshK0tC93jOKMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as index1YqB0VsnmQMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/structures/index.vue?macro=true";
import { default as indexpeY7SKkAeoMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/users/[id]/index.vue?macro=true";
import { default as add6zTVkTc51dMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/users/add.vue?macro=true";
import { default as index5Z0xgCd059Meta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/users/index.vue?macro=true";
import { default as adminK7COzeWH6KMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin.vue?macro=true";
import { default as accountqgJWZF7hDyMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/account.vue?macro=true";
import { default as indexQrgdhQyVfHMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/archive/index.vue?macro=true";
import { default as entouragekjD6W33k1bMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationstHBJyoDeUSMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as health1N4t01bEsXMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoOWk31P2UVdMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupation3MY6h3dyaNMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdUSugAgVssyMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printUpQEu0stQxMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexNfzSTUiZeaMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/index.vue?macro=true";
import { default as editv8wMOkxPPUMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editKrHiUWujzSMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editoy0rvwjkFLMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addfXtHAofRhHMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editEUeM58bOETMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addVozJh2xAyTMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as edit4Gq46bc9EFMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addS5UMK65DzPMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editQfxkHYnETIMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as index3gZvCxTLetMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/index.vue?macro=true";
import { default as addZvPteQKaDeMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/add.vue?macro=true";
import { default as history9o91potn0qMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/history.vue?macro=true";
import { default as printS29SO4uNNvMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewPZVEtyfWCbMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/overview.vue?macro=true";
import { default as partners59ylzDeSyuMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/partners.vue?macro=true";
import { default as statsbPU90Yq3pQMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/stats.vue?macro=true";
import { default as index3MQwAgW6RQMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/structure/index.vue?macro=true";
import { default as subjectsK7ro6EwqrJMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/structure/subjects.vue?macro=true";
import { default as usersKoiSJBE4UhMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/users.vue?macro=true";
import { default as appokm71s5bxlMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app.vue?macro=true";
import { default as adminj3LZuc8WXiMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/admin.vue?macro=true";
import { default as loginlmqJPVq4oMMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/login.vue?macro=true";
import { default as logoutKgqcyc8tbUMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/logout.vue?macro=true";
import { default as validate_45tokenIWKR5tkqqqMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/validate-token.vue?macro=true";
import { default as managerRV2qHonD1zMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/cgu/manager.vue?macro=true";
import { default as usertjApDaeKdMMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/cgu/user.vue?macro=true";
import { default as cguPibLKuFvrhMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitelOtK4gt3pJMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountDYil36iwVbMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45token3UPSQOY6ThMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45user_45iczKI9qtdV69Meta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45userD9AKpSmdwmMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/unknown-user.vue?macro=true";
import { default as index79Nn57WTzBMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/index.vue?macro=true";
import { default as mentions_45legalesqt1IIhcULLMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteW0T9sOoRmwMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/politique-confidentialite.vue?macro=true";
import { default as registerwqA4OyPd3zMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/register.vue?macro=true";
import { default as statistiquesbujhfq14HQMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/statistiques.vue?macro=true";
import { default as storiesb05N8ehSOkMeta } from "/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminK7COzeWH6KMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/integrations.vue")
  },
  {
    name: overvieweNerRGnAPBMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overvieweNerRGnAPBMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appokm71s5bxlMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app.vue"),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/account.vue")
  },
  {
    name: indexQrgdhQyVfHMeta?.name ?? "app-beneficiaries-id-archive",
    path: "beneficiaries/:id()/archive",
    meta: indexQrgdhQyVfHMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/archive/index.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupation3MY6h3dyaNMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupation3MY6h3dyaNMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printUpQEu0stQxMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexNfzSTUiZeaMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexNfzSTUiZeaMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/beneficiaries/index.vue")
  },
  {
    name: editv8wMOkxPPUMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editv8wMOkxPPUMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editKrHiUWujzSMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editKrHiUWujzSMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editQfxkHYnETIMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editQfxkHYnETIMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printS29SO4uNNvMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: overviewPZVEtyfWCbMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewPZVEtyfWCbMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminj3LZuc8WXiMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginlmqJPVq4oMMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutKgqcyc8tbUMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenIWKR5tkqqqMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguPibLKuFvrhMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitelOtK4gt3pJMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountDYil36iwVbMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45token3UPSQOY6ThMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45iczKI9qtdV69Meta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/unknown-user-ic.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userD9AKpSmdwmMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index79Nn57WTzBMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesqt1IIhcULLMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteW0T9sOoRmwMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerwqA4OyPd3zMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesbujhfq14HQMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesb05N8ehSOkMeta || {},
    component: () => import("/build/3cfe57ef-02b1-4519-946d-cabc386ff5a9/pages/stories.vue")
  }
]